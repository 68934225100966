import React from "react";
import { Layout } from "../components/Layout/Layout";
import { HomiesPageQuery } from "../../graphql-types";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SEO } from "../components/Seo";
import { Subtitle } from "../components/Subtitle/Subtitle";
import { SubtitleImage } from "../components/Image/SubtiteImage";
import { graphql } from "gatsby";
import { usePageContext } from "../components/Context/pageContext";
import * as styles from "../styles/pages/homies.module.scss";
import { HomieCard } from "../modules/Homie/HomieCard";

interface Props {
  data: HomiesPageQuery;
}

const Homies: React.FC<Props> = ({ data }) => {
  const { page, homies } = data;
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata} />
      <PageTitle content={page?.content?._rawTitle[lang]} />
      <SubtitleImage
        image={page?.content?.image?.asset?.gatsbyImageData}
        alt={"concave"}
      />
      <Subtitle content={page?.content?._rawSubtitle[lang]} />
      <div className={styles.homiesWrapper}>
        {homies.edges.map((homie: any, index: number) => (
          <React.Fragment key={`homie-card-${index}`}>
            <HomieCard homie={homie.node} />
          </React.Fragment>
        ))}
      </div>
    </Layout>
  );
};

export default Homies;

export const QUERY = graphql`
  query HomiesPage {
    page: sanityHomiesPage {
      content {
        _rawTitle
        _rawSubtitle
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }

    homies: allSanityHomie {
      edges {
        node {
          name
          url
          _rawDescription
          photo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
