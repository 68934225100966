import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./homieCard.module.scss";
import { SanityHomie } from "../../../graphql-types";
import { usePageContext } from "../../components/Context/pageContext";

interface Props {
  homie: SanityHomie;
}

export const HomieCard: React.FC<Props> = ({ homie }) => {
  const { lang } = usePageContext();

  return (
    <div className={styles.wrapper}>
      <GatsbyImage
        image={homie.photo?.asset?.gatsbyImageData}
        alt={"concave"}
        className={styles.image}
        objectFit={"contain"}
      />
      <div className={styles.info}>
        <div className={styles.name}>{homie.name}</div>
        <div className={styles.desc}>{homie._rawDescription[lang]}</div>
        <a className={styles.link} href={homie.url ?? ""} target="_blank">
          {"Link"}
        </a>
      </div>
    </div>
  );
};
